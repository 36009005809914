.box {
  position: absolute;
  right: 100%;
  top: 60%;
  border: 1px solid #e4e6ef;
  width: 325px;
  z-index: 10;
  background-color: #ffffff;
  border-radius: 4px;
  padding: 5px;
}

.notif-title {
  font-size: 14px;
  padding: 10px;
  padding-left: 20px;
  cursor: pointer;
  border-bottom: 1px solid #e4e6ef;
}

.notif-title:hover {
  background-color: #3699ff;
  color: #ffffff;
  border-radius: 4px;
}

.hover-custom {
  color: #f64e60;
}

.hover-custom:hover {
  color: #ffffff;
}
